<template>
    <div>
        <template v-if="auth">
            <v-footer color="transparent" class="px-6 position-absolute w-100 bottom-0" v-if="$route.name == 'SignUpBasic' && $route.name != 'Pricing'">
                <v-card class="flex" color="transparent">
                    <v-card-text class="px-0">
                        <v-row>
                            <v-col cols="12" md="6">
                                <div class="copyright text-body-2 ls-0 text-white">
                                    © {{ new Date().getFullYear() }}, By
                                    <a href="https://tripletwo.tw" class="
                      text-decoration-none text-white text-body-2
                      ls-0
                      font-weight-bold
                    " target="_blank">Triple Two</a>.
                                </div>
                            </v-col>
                            <v-col cols="10" md="6" class="d-flex justify-end">
                                <ul v-for="item in footer" :key="item.linkName" class="d-flex list-style-none">
                                    <li>
                                        <a :href="item.link" class="
                        text-decoration-none text-white text-body-2
                        ls-0
                        no-default-hover
                      " target="_blank">{{ item.linkName }}</a>
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-footer>
            <v-footer color="transparent" class="mt-10 pb-5" v-else>
                <v-card class="flex" color="transparent">
                    <v-card-text class="px-0">
                        <v-row>
                            <v-col cols="12" lg="8" class="mb-2 mx-auto text-center">
                                <a v-for="item in footer" :key="item.linkName" :href="item.link" class="text-decoration-none text-secondary ls-0 mx-4 text-md" target="_blank">{{ item.linkName }}</a>
                            </v-col>
                            <v-col cols="8" class="mx-auto text-center">
                                <v-btn v-for="item in icons" :key="item.icon" icon width="31" :ripple="false" class="mx-3 text-secondary no-default-hover" link :href="item.link" target="_blank">
                                    <v-icon size="18">{{ item.icon }}</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="8" class="mx-auto text-center">
                                <p class="
                    copyright
                    text-secondary
                    font-weight-light
                    text-md
                    ls-0
                  ">
                                    Copyright © {{ new Date().getFullYear() }}
                                    By
                                    <a href="https://tripletwo.tw" class="text-decoration-none btn-hover text-body ls-0" target="_blank">Triple Two</a>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-footer>
        </template>
        <template v-else>
            <v-footer color="transparent" class="px-6">
                <v-card class="flex" color="transparent">
                    <v-card-text class="px-0">
                        <v-row>
                            <v-col cols="12" md="6">
                                <div class="copyright text-body-2 ls-0 text-muted">
                                    © {{ new Date().getFullYear() }}, By
                                    <a href="https://tripletwo.tw" class="
                      text-decoration-none text-typo text-body-2
                      ls-0
                      font-weight-bold
                    " target="_blank">Triple Two</a>.
                                </div>
                            </v-col>
                            <v-col cols="10" md="6" class="d-flex justify-end">
                                <ul v-for="item in footer" :key="item.linkName" class="d-flex list-style-none">
                                    <li>
                                        <a :href="item.link" class="
                        text-decoration-none text-muted text-body-2
                        ls-0
                        btn-dark-hover
                        no-default-hover
                      " target="_blank">{{ item.linkName }}</a>
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-footer>
        </template>
    </div>
</template>
<script>
export default {
    name: "content-footer",
    props: {
        auth: Boolean,
    },
    data() {
        return {
            footer: [{
                linkName: "關於我們",
                link: "https://tripletwo.tw/%E4%B8%89%E5%80%8B%E4%BA%8C%E5%AE%98%E7%B6%B2/triple-two%E6%96%9C%E6%A7%93%E5%8A%A0%E7%9B%9F/",
            }, {
                linkName: "線上購物",
                link: "https://shop.tripletwo.tw/",
            }, ],
            icons: [{
                    icon: "fab fa-facebook",
                    link: "https://www.facebook.com/profile.php?id=100092038361553",
                },
                {
                    icon: "fab fa-instagram",
                    link: "https://instagram.com/tripletwo_cafe",
                },
                {
                    icon: "fab fa-line",
                    link: "https://line.me/ti/p/~@tripletwo",
                },
            ],
        };
    },
};
</script>