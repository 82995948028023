// store/index.js
import Vue from 'vue'
import Vuex from 'vuex'

const cookieparser = process.server ? require('cookieparser') : undefined

Vue.use(Vuex)

const state = {
  // single source of data
  auth: null,
  userIdPath: null,
}


const mutations = {
  // isolated data mutations
  setAuth (state, auth) {
    state.auth = auth
  },
  setUserIdPath (state, userIdPath) {
    state.userIdPath = userIdPath
  },

}


const actions = {
  // asynchronous operations
  nuxtServerInit (context, req) {
    let auth = null
    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      try {
        console.log(parsed)
        auth = JSON.parse(parsed.auth)
      } catch (err) {
        // No valid cookie found
      }
    }
    context.commit('setAuth', auth)
  }
}


const getters = {
  // reusable data accessors
  isAuthenticated (state) {
    return state.auth
  }
}


const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters
})

export default store